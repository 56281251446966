// CSS overrides for datatables
.ngx-datatable.bootstrap {
  font-size: inherit;
  font-family: var(--val-font-family-2);
  margin-bottom: 3rem;

  .datatable-header {
    background-color: var(--val-white-3);
    border: 1px solid var(--val-light-gray-3);
    font-size: 0.85rem;

    .datatable-header-cell {
      border: none;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      word-break: break-word;
    }
  }

  .datatable-body {
    .datatable-body-row {
      border-color: var(--val-light-gray-2);

      &:hover {
        background-color: var(--val-blue-3) !important;
      }

      a {
        color: var(--val-primary);
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      &.datatable-row-even {
        background-color: inherit;
      }

      &.active {
        background-color: var(--val-white-3);
        color: inherit;
      }

      .datatable-body-cell {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    .datatable-row-wrapper:first-of-type .datatable-body-row {
      border: none;
    }
  }

  &.selectable {
    .datatable-body {
      .datatable-body-row {
        cursor: pointer;
      }
    }
  }

  .datatable-footer {
    background-color: var(--val-white);
    border-top: 3px double var(--val-light-gray-3);
    color: inherit;
    font-size: 0.85rem;

    .datatable-pager {
      li {
        &.disabled a {
          color: var(--val-gray-2) !important;
          background-color: transparent !important;
        }

        &.active a {
          background-color: #9e9e9e33 !important;
        }
      }

      a {
        color: var(--val-gray);
        background-color: transparent !important;

        &:hover {
          color: var(--val-dark-gray);
          background-color: #9e9e9e33 !important;
        }
      }
    }

    .page-count {
      padding-left: 0.75rem;
    }
  }

  &.dt-small {
    font-size: 0.8rem;

    .datatable-header {
      font-size: 0.8rem;

      .datatable-header-cell {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }

    .datatable-body {
      .datatable-body-row {
        .datatable-body-cell {
          padding-top: 0.325rem;
          padding-bottom: 0.325rem;
        }
      }
    }

    .datatable-footer {
      font-size: 0.8rem;
    }
  }

  // This doesn't work correctly yet (becomes active, but never deactivates
  //.progress-linear {
  //  .container {
  //    @extend .progress;
  //    max-width: 100% !important;
  //    --bs-progress-border-radius: 0;
  //    --bs-progress-height: 0.5rem;
  //
  //    .bar {
  //      @extend .progress-bar;
  //      @extend .progress-bar-striped;
  //      @extend .progress-bar-animated;
  //      width: 100%;
  //    }
  //  }
  //  @keyframes query {
  //    0% {
  //      opacity: 1;
  //      transform: translateX(35%) scale(0.3, 1);
  //    }
  //
  //    100% {
  //      opacity: 0;
  //      transform: translateX(-50%) scale(0, 1);
  //    }
  //  }
  //}
}

.dt-icon {
  font-family: var(--val-icon-font);
  font-size: 1.5rem;
  color: var(--val-gray);
  text-decoration: none;
  display: inline-block;
  line-height: 1rem;
  letter-spacing: 0.5px;
  padding: 0;

  &:not(:last-of-type) {
    padding-right: 0.5rem;
  }

  &:hover {
    color: var(--val-primary);
  }

  &.article:before {
    content: '\ef42'; // article
  }

  &.status:before,
  &.check:before {
    content: '\e86c'; // check_circle
  }

  &.status {
    vertical-align: text-bottom;

    &.active:before {
      color: var(--val-green);
    }
  }

  &.copy:before {
    content: '\e14d'; // content_copy
  }

  &.delete:before {
    content: '\e872'; // delete
  }

  &.edit:before {
    content: '\e3c9'; // edit
  }

  &.quote:before {
    content: '\f1b6'; // request_quote
  }

  &.view:before {
    content: '\e8f4'; // visibility
  }

  &.true:before {
    content: '\e5ca'; // check
    color: var(--bs-success);
  }

  &.false:before {
    content: '\e5cd'; // close
    color: var(--bs-danger);
  }

  &.btn {
    color: var(--val-gray) !important;
    text-decoration: none !important;
  }

  &.md {
    font-size: 1.3rem !important;
    line-height: 1rem !important;
    padding: 0 !important;
  }
}

.btn:disabled {
  border: none !important;
}

.ngx-datatable.dt-small .dt-icon {
  font-size: 1.125rem;

  &:not(:last-of-type) {
    padding-right: 0.25rem;
  }
}

.ngx-datatable.widget {
  .datatable-header {
    background-color: #fff !important;
    border: none !important;
    border-bottom: 1px solid var(--val-gray-2) !important;

    .datatable-header-cell {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      font-weight: bold;
      color: var(--val-dark-gray-2);
    }
  }

  .datatable-body .empty-row {
    padding: 0.75rem;
  }
}

.align-ngx-row-center .datatable-row-center {
  align-items: center;
}

.w-fit-content {
  width: fit-content;
}
