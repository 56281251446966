/* Rules for sizing the icon. */
.material-icons,
.material-icons-outlined,
.val-icon {
  vertical-align: bottom;

  &.md-18 {
    font-size: 18px;
  }

  &.md-21 {
    font-size: 21px;
  }

  &.md-24 {
    font-size: 24px;
  }

  // default
  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  /* Rules for using icons as black on a light background. */
  &.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }

  &.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }

  /* Rules for using icons as white on a dark background. */
  &.md-light {
    color: rgba(255, 255, 255, 1);
  }

  &.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
}

.val-icon {
  &.i-18 {
    font-size: 18px;
  }

  &.i-24 {
    font-size: 24px;
  }

  &.i-36 {
    font-size: 36px;
  }

  &.i-48 {
    font-size: 48px;
  }

  font-family: var(--val-icon-font);
  font-style: normal;
  vertical-align: bottom;

  &.toast-icon {
    font-size: 24px;
    padding-right: 4px;
    vertical-align: middle;
  }

  &.cancel:after,
  &.rejected:after {
    content: '\e5c9'; // cancel
  }

  &.danger:after,
  &.toast-icon.alert-warning:after,
  &.toast-icon.alert-danger:after {
    content: '\f083'; // warning_amber
  }

  &.info:after,
  &.new:after {
    content: '\e88e'; // info
  }

  &.approved:after,
  &.success:after,
  &.toast-icon.alert-success:after {
    content: '\e92d'; // check_circle_outline
  }

  &.warning:after,
  &.toast-icon.alert-warning:after {
    content: '\f083'; // warning_amber
  }

  &.error-outline:after {
    content: '\e001'; // error_outline
  }

  &.address:after {
    content: '\e153'; // flag
  }

  &.add-box:after {
    content: '\e146'; // add_box
  }

  &.calendar-month:after {
    content: '\ebcc'; // calendar_month
  }

  &.remove-box:before {
    content: '\e909';
  }

  &.box1:after {
    content: '\e1a1'; // inventory_2
  }

  &.close:after {
    content: '\e5cd'; // close
  }

  &.doc1:after {
    content: '\e14f'; // content_paste
  }

  &.dollar:after {
    content: '\f041'; // paid
  }

  &.email:after {
    content: '\e0be'; // email
  }

  &.expand-less:after {
    content: '\e5ce'; // expand_less
    pointer-events: none;
  }

  &.expand-more:after {
    content: '\e5cf'; // expand_more
    pointer-events: none;
  }

  &.hash:after {
    content: '\e9ef'; // tag
  }

  &.help:after {
    content: '\e8fd'; // help_outline
  }

  &.home:after {
    content: '\e88a'; // home
  }

  &.msg1:after {
    content: '\e0cb'; // chat_bubble_outline
  }

  &.mobile:after {
    content: '\e325'; // phone_iphone
  }

  &.password:after {
    content: '\e897'; // lock
  }

  &.pending:after {
    content: '\ef64'; // pending
  }

  &.percent:after {
    content: '\eb58'; // percent
  }

  &.phone:after {
    content: '\e0cd'; // phone
  }

  &.postal:after {
    content: '\e55f'; // place
  }

  &.search:after {
    content: '\e8b6'; // search
  }

  &.user:after {
    content: '\e7ff'; //person_outline
  }

  &.delete:after {
    content: '\e872'; // delete
  }

  &.edit:after {
    content: '\e3c9'; // edit
  }

  &.edit-note:after {
    content: '\e745'; // write_document
  }
}

.icon {
  &.import,
  &.delete,
  &.empty-cart,
  &.empty-quote {
    display: none;
  }

  &:before {
    font-family: var(--val-icon-font);
    font-size: 18px;
  }

  &.add-product:before {
    content: '\e03b'; // playlist_add
    position: relative;
    top: 1px;
  }

  &.add-box:before {
    content: '\e146'; // add_box
  }

  &.remove-box:before {
    content: '\e909';
  }

  &.delete:before {
    content: '\e872'; // delete
  }

  &.download:before,
  &.import:before {
    content: '\e2c4'; // file_download
  }

  &.export:before {
    content: '\e2c6'; // file_upload
  }

  &.empty-cart, &.empty-quote {
    &:before {
      content: '\e928'; // remove_shopping_cart
    }
  }

  &.expand-less:before {
    content: '\e5ce'; // expand_less
    pointer-events: none;
  }

  &.expand-more:before {
    content: '\e5cf'; // expand_more
    pointer-events: none;
  }

  &.quote:before {
    content: '\f1b6'; // request_quote
  }

  &.delete,
  &.empty-cart,
  &.empty-quote {
    &:hover {
      color: var(--val-red-2);
    }
  }
}
