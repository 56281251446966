@keyframes db1 {
  0%,
  10% {
    background-size: 8px 0, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(50% - 2px), 0 calc(50% + 2px), 50% 50%, 50% calc(50% - 2px), 50% calc(50% + 2px),
      100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
  }

  90%,
  100% {
    background-size: 8px 100%, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 -2px, 0 calc(100% + 2px), 50% 50%, 50% -2px, 50% calc(100% + 2px), 100% 50%, 100% -2px,
      100% calc(100% + 2px);
  }
}

.dots-bars-2 {
  width: 40px;
  height: 40px;
  --c: linear-gradient(currentColor 0 0);
  --r1: radial-gradient(farthest-side at bottom, currentColor 93%, #0000);
  --r2: radial-gradient(farthest-side at top, currentColor 93%, #0000);
  background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2);
  background-repeat: no-repeat;
  animation: db2 1s infinite alternate;
}

@keyframes db2 {
  0%,
  25% {
    background-size: 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(50% - 2px), 0 calc(50% + 2px), 50% 50%, 50% calc(50% - 2px), 50% calc(50% + 2px),
      100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
  }

  50% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(50% - 2px), 50% calc(50% + 2px),
      100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
  }

  75% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(0% - 2px), 50% calc(100% + 2px),
      100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
  }

  95%,
  100% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(0% - 2px), 50% calc(100% + 2px),
      100% 50%, 100% calc(0% - 2px), 100% calc(100% + 2px);
  }
}

.dots-bars-3 {
  width: 40px;
  height: 26px;
  --c: linear-gradient(currentColor 0 0);
  background: var(--c) 0 100%, var(--c) 50% 100%, var(--c) 100% 100%;
  background-size: 8px calc(100% - 4px);
  background-repeat: no-repeat;
  position: relative;
}

.dots-bars-3:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  left: 0;
  top: 0;
  animation: db3-1 1.5s linear infinite alternate, db3-2 0.75s cubic-bezier(0, 200, 0.8, 200) infinite;
}

@keyframes db3-1 {
  100% {
    left: calc(100% - 8px);
  }
}

@keyframes db3-2 {
  100% {
    top: -0.1px;
  }
}

.dots-bars-4 {
  width: 40px;
  height: 20px;
  --c: radial-gradient(farthest-side, currentColor 93%, #0000);
  background: var(--c) 0 0, var(--c) 50% 0, var(--c) 100% 0;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  position: relative;
  animation: db4-0 1s linear infinite alternate;
}

.dots-bars-4:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 12px;
  background: currentColor;
  left: 0;
  top: 0;
  animation: db4-1 1s linear infinite alternate, db4-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

@keyframes db4-0 {
  0% {
    background-position: 0 100%, 50% 0, 100% 0;
  }

  8%,
  42% {
    background-position: 0 0, 50% 0, 100% 0;
  }

  50% {
    background-position: 0 0, 50% 100%, 100% 0;
  }

  58%,
  92% {
    background-position: 0 0, 50% 0, 100% 0;
  }

  100% {
    background-position: 0 0, 50% 0, 100% 100%;
  }
}

@keyframes db4-1 {
  100% {
    left: calc(100% - 8px);
  }
}

@keyframes db4-2 {
  100% {
    top: -0.1px;
  }
}

.dots-bars-5 {
  width: 40px;
  height: 30px;
  --c: linear-gradient(currentColor 0 0);
  background: var(--c) 0 100%/8px 30px, var(--c) 50% 100%/8px 20px, var(--c) 100% 100%/8px 10px;
  background-repeat: no-repeat;
  position: relative;
  clip-path: inset(-100% 0);
}

.dots-bars-5:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  left: -16px;
  top: 0;
  animation: db5-1 2s linear infinite, db5-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

@keyframes db5-1 {
  0% {
    left: -16px;
    transform: translateY(-8px);
  }

  100% {
    left: calc(100% + 8px);
    transform: translateY(22px);
  }
}

@keyframes db5-2 {
  100% {
    top: -0.1px;
  }
}

.dots-bars-6 {
  width: 40px;
  height: 20px;
  --c: radial-gradient(farthest-side, currentColor 93%, #0000);
  background: var(--c) 0 0, var(--c) 50% 0;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  position: relative;
  clip-path: inset(-200% -100% 0 0);
  animation: db6-0 1.5s linear infinite;
}

.dots-bars-6:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 12px;
  background: currentColor;
  left: -16px;
  top: 0;
  animation: db6-1 1.5s linear infinite, db6-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

.dots-bars-6:after {
  content: '';
  position: absolute;
  inset: 0 0 auto auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  animation: db6-3 1.5s linear infinite;
}

@keyframes db6-0 {
  0%,
  30% {
    background-position: 0 0, 50% 0;
  }

  33% {
    background-position: 0 100%, 50% 0;
  }

  41%,
  63% {
    background-position: 0 0, 50% 0;
  }

  66% {
    background-position: 0 0, 50% 100%;
  }

  74%,
  100% {
    background-position: 0 0, 50% 0;
  }
}

@keyframes db6-1 {
  90% {
    transform: translateY(0);
  }

  95% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(15px);
    left: calc(100% - 8px);
  }
}

@keyframes db6-2 {
  100% {
    top: -0.1px;
  }
}

@keyframes db6-3 {
  0%,
  80%,
  100% {
    transform: translate(0);
  }

  90% {
    transform: translate(26px);
  }
}

.dots-bars-7 {
  width: 40px;
  height: 40px;
  --c: linear-gradient(currentColor 0 0);
  background: var(--c) 0 0, var(--c) 0 100%, var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 0, var(--c) 100% 100%;
  background-size: 8px 50%;
  background-repeat: no-repeat;
  animation: db7-0 1s infinite;
  position: relative;
  overflow: hidden;
}

.dots-bars-7:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  top: calc(50% - 4px);
  left: -8px;
  animation: inherit;
  animation-name: db7-1;
}

@keyframes db7-0 {
  16.67% {
    background-size: 8px 30%, 8px 30%, 8px 50%, 8px 50%, 8px 50%, 8px 50%;
  }

  33.33% {
    background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 50%, 8px 50%;
  }

  50% {
    background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 30%;
  }

  66.67% {
    background-size: 8px 50%, 8px 50%, 8px 30%, 8px 30%, 8px 30%, 8px 30%;
  }

  83.33% {
    background-size: 8px 50%, 8px 50%, 8px 50%, 8px 50%, 8px 30%, 8px 30%;
  }
}

@keyframes db7-1 {
  20% {
    left: 0px;
  }

  40% {
    left: calc(50% - 4px);
  }

  60% {
    left: calc(100% - 8px);
  }

  80%,
  100% {
    left: 100%;
  }
}

.dots-bars-8 {
  width: 40px;
  height: 40px;
  --c: linear-gradient(currentColor 0 0);
  background: var(--c) 0 0, var(--c) 0 100%, var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 0, var(--c) 100% 100%;
  background-size: 8px 50%;
  background-repeat: no-repeat;
  animation: db8-0 2s infinite;
  position: relative;
  overflow: hidden;
}

.dots-bars-8:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  top: calc(50% - 4px);
  left: -8px;
  animation: inherit;
  animation-name: db8-1;
}

@keyframes db8-0 {
  16.67% {
    background-size: 8px 30%, 8px 30%, 8px 50%, 8px 50%, 8px 50%, 8px 50%;
  }

  33.33% {
    background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 50%, 8px 50%;
  }

  50% {
    background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 50%, 8px 50%;
  }

  66.67% {
    background-size: 8px 50%, 8px 50%, 8px 30%, 8px 30%, 8px 50%, 8px 50%;
  }

  83.33% {
    background-size: 8px 50%, 8px 50%, 8px 50%, 8px 50%, 8px 50%, 8px 50%;
  }
}

@keyframes db8-1 {
  20% {
    left: 0px;
  }

  40%,
  100% {
    left: calc(50% - 4px);
  }
}

.dots-bars-9 {
  width: 40px;
  height: 40px;
  --c: linear-gradient(currentColor 0 0);
  background: var(--c) 0 0, var(--c) 0 100%, var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 0, var(--c) 100% 100%;
  background-size: 8px 50%;
  background-repeat: no-repeat;
  animation: db9-0 2s infinite;
  position: relative;
  overflow: hidden;
}

.dots-bars-9:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  top: calc(50% - 4px);
  left: -8px;
  animation: inherit;
  animation-name: db9-1;
}

@keyframes db9-0 {
  16.67% {
    background-size: 8px 30%, 8px 30%, 8px 50%, 8px 50%, 8px 50%, 8px 50%;
  }

  33.33% {
    background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 50%, 8px 50%;
  }

  50% {
    background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 50%, 8px 50%;
  }

  66.67% {
    background-size: 8px 50%, 8px 50%, 8px 30%, 8px 30%, 8px 50%, 8px 50%;
  }

  83.33% {
    background-size: 8px 50%, 8px 50%, 8px 50%, 8px 50%, 8px 50%, 8px 50%;
  }
}

@keyframes db9-1 {
  20% {
    left: 0px;
  }

  40%,
  70% {
    left: calc(50% - 4px);
  }

  80%,
  85% {
    left: 8px;
    top: calc(50% - 4px);
  }

  100% {
    left: 8px;
    top: -8px;
  }
}

.dots-bars-10 {
  width: 70px;
  height: 30px;
  overflow: hidden;
  position: relative;
}

.dots-bars-10::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  inset: 0;
  margin: auto;
  background: currentColor;
  transform-origin: bottom;
  animation: db10-1 3s infinite;
}

.dots-bars-10::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 14px;
  bottom: calc(50% - 4px);
  background: currentColor;
  animation: db10-2 3s infinite;
}

@keyframes db10-1 {
  0%,
  10% {
    transform: translate(0) scale(1);
    box-shadow: 60px 0, 60px 0;
  }

  20%,
  40% {
    transform: translate(20px) scale(1);
    box-shadow: 60px 0, 60px 0;
  }

  48% {
    transform: translate(20px) scale(1);
    box-shadow: 8px 0, 60px 0;
  }

  50% {
    transform: translate(20px) scale(1.5);
    box-shadow: 0 0, 60px 0;
  }

  58% {
    transform: translate(20px) scale(1.5);
    box-shadow: 0 0, 8px 0;
  }

  60%,
  70% {
    transform: translate(20px) scale(2);
    box-shadow: 0 0, 0 0;
  }

  85% {
    transform: translate(-50px) scale(2);
    box-shadow: 0 0, 0 0;
  }

  87% {
    transform: translate(-50px) scale(1);
    box-shadow: 0 0, 0 0;
  }

  100% {
    transform: translate(0) scale(1);
    box-shadow: 0 0, 0 0;
  }
}

@keyframes db10-2 {
  20%,
  70% {
    left: 50%;
  }

  0%,
  10%,
  85%,
  100% {
    left: -25px;
  }
}

/**/
.loading {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 130px;
  place-items: center;
}

* {
  box-sizing: border-box;
}
