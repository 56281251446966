// Custom Styles
//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";

// Buttons
$btn-font-family: 'Poppins', sans-serif;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-font-size-sm: 0.75rem;
$btn-font-size-lg: 1rem;

// Alerts
$alert-border-radius: 0;
$alert-link-font-weight: 500;

// Inputs
$input-bg: #f1f1f1;
$input-border-radius: 0;
$input-border-color: #f1f1f1;
$input-padding-x: 5px;
$input-padding-y: 10px;
$input-color: #222222;
$input-height: 50px;
$input-font-size: 16px;
$input-group-addon-bg: #f1f1f1;
$input-group-addon-border-color: #f1f1f1;
$form-label-margin-bottom: 5px !important;
$card-height: 100%;

.form-control:focus {
  border-color: #0067a4 !important;
  box-shadow: none !important;
  background-color: #f1f1f1 !important;
}

.input-validation-error {
  border-color: #f93 !important;
  box-shadow: inset 0 0 1px 3px #fff3cd;
}

// Cards
$card-border-radius: 0;

// Navs
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-border-color: #222;
$nav-tabs-link-active-color: #0067a4;
$nav-link-color: #737373;

// Pagination
$pagination-active-bg: #0067a4;
$pagination-border-radius: none;
$pagination-focus-color: #0067a4;
$pagination-hover-color: #0067a4;
$pagination-focus-box-shadow: none;

// Form Select
$form-select-focus-box-shadow: none;
$form-select-border-radius: 0;
$form-select-border-color: #f1f1f1;

.form-select {
  background-color: #f1f1f1 !important;
}

.btn:focus {
  box-shadow: none !important;
}

.btn {
  text-transform: uppercase;

  &.btn-outline-primary {
    border-color: var(--val-primary);
    color: var(--val-primary);

    &:hover {
      background-color: var(--val-primary);
    }
  }

  &.btn-outline-warning {
    border-color: var(--val-orange);
    color: var(--val-orange);

    &:hover {
      border-color: var(--val-orange) !important;
      background-color: var(--val-light-gray) !important;
    }
  }

  &.btn-primary:hover {
    border-color: var(--val-primary-hover);
    background-color: var(--val-primary-hover);
  }
}

.alert-danger {
  color: var(--val-red-2) !important;
  border-color: var(--val-red-2) !important;
  background-color: var(--val-red-3) !important;

  .alert-link {
    color: var(--val-red-2) !important;
  }
}

.alert-info {
  color: var(--val-blue) !important;
  border-color: var(--val-blue-2) !important;
  background-color: var(--val-blue-3) !important;

  .alert-link {
    color: var(--val-blue) !important;
  }
}

.alert-success {
  color: var(--val-green) !important;
  border-color: var(--val-green) !important;
  background-color: var(--val-green-3) !important;

  .alert-link {
    color: var(--val-green) !important;
  }
}

.alert-warning {
  color: var(--val-orange-2) !important;
  border-color: var(--val-orange) !important;
  background-color: var(--val-orange-3) !important;

  .alert-link {
    color: var(--val-orange-2) !important;
  }
}

// @import "node_modules/bootstrap/scss/bootstrap";
