@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'site-themes/variables-ca';
@import 'loading-icons';
@import 'shared/datatables';
@import 'shared/icons';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
  margin-top: 10px;
  float: right;
}

:root {
  --bs-breadcrumb-divider: '>';
  --bs-breadcrumb-item-active-color: #222;
  // Typography
  --val-font-family: 'Poppins', sans-serif;
  --val-font-family-2: 'Open Sans', sans-serif;
  --val-icon-font: 'Material Icons Outlined';
  --val-h1-font-size: 28px;
  --val-h2-font-size: 20px;
  --val-p-font-size: 16px;

  // Colors
  --val-primary: #0067a4;
  --val-primary-hover: #00417b;

  --val-white: #fff;
  --val-white-2: #f1f1f1;
  --val-white-3: #f7f7f7;

  --val-light-gray: #eee;
  --val-light-gray-2: #ddd;
  --val-light-gray-3: #ccc;

  --val-gray: #737373;
  --val-gray-2: #aaa;

  --val-dark-gray: #222;
  --val-dark-gray-2: #666;

  --val-orange: #f93;
  --val-orange-2: #de6e00;
  --val-orange-3: #fff3cd;
  --val-orange-4: #fbe4b5;
  --val-orange-5: #fff9e6;
  --val-red-2: #dc3545;
  --val-red-3: #feeeef;
  --val-red-4: #f9d2d6;
  --val-green: #10a700;
  --val-green-2: #b1cc91;
  --val-green-3: #f5fefa;
  --val-blue: #0066a4;
  --val-blue-2: #8fafd7;
  --val-blue-3: #f7fdff;
  --val-blue-4: #3876b0;
  --val-blue-5: #a0daff;
}

// Global hight utilities; currently being used for input and inputgroup button heights
.h35 {
  height: 35px;
}

.h50 {
  height: 50px;
}

body {
  font-family: var(--val-font-family);
  color: var(--val-gray);
  font-size: 0.95rem;
  line-height: 1.5;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

.page-content {
  position: relative;
  z-index: 1;
}

._mobile {
  display: block;
}

._desktop {
  display: none;
}

@media (min-width: 992px) {
  ._mobile,
  #mobile {
    display: none !important;
  }

  ._desktop {
    display: block;
  }

  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: calc(100% - 30px) !important;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1400px !important;
  }
}

@media (min-width: 1640px) {
  .container {
    max-width: 1600px !important;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 100% !important;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 0.875rem;
  }
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: var(--val-dark-gray);
  letter-spacing: 0.75px;

  &.title {
    text-align: center;
    margin-bottom: 30px;

    &:after {
      display: block;
      width: 50px;
      border-bottom: 3px solid var(--val-primary);
      content: '';
      padding-top: 10px;
      margin: 0 auto;
    }

    &.left {
      text-align: left;

      &:after {
        margin: 0 auto 0 0;
      }
    }

    &.right {
      text-align: right;

      &:after {
        margin: 0 0 0 auto;
      }
    }
  }
}

.font-primary {
  font-family: var(--val-font-family) !important;
}

.font-secondary {
  font-family: var(--val-font-family-2) !important;
}

.font-light {
  color: var(--val-gray-2) !important;
}

.font-medium {
  color: var(--val-gray) !important;
}

.font-dark {
  color: var(--val-dark-gray) !important;
}

.strike-thru {
  text-decoration: line-through !important;
}

.fw-4 {
  font-weight: 400 !important;
}

.fw-5 {
  font-weight: 500 !important;
}

.fw-6 {
  font-weight: 600 !important;
}

a {
  color: var(--val-dark-gray);
  text-decoration: none;

  &:hover {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
  }

  &:hover {
    color: var(--val-primary);
  }

  &:before,
  &:after,
  &:hover:before,
  &:hover:after {
    color: inherit;
  }

  &:not([href]) {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  &[href*='tel:'] {
    text-decoration: none;
  }

  &.btn.fancy {
    border-width: 3px;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 0.875rem;
    padding: 0 15px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    line-height: 1rem;

    &:after {
      position: relative;
      z-index: 1;
      font-size: 1.2rem;
      padding-left: 5px;
      left: 0;
      font-weight: 400;
      font-family: var(--val-icon-font);
      content: '\e5c8'; // arrow_forward
    }

    &:hover {
      text-decoration: none;
      background: none;

      &:after {
        left: 5px;
        text-decoration: none;
      }
    }

    &.btn-sm:after {
      font-size: 0.875rem;
    }

    &.btn-primary {
      &:hover {
        border-color: var(--bs-primary);
        color: var(--bs-primary);
      }
    }

    &.btn-secondary {
      &:hover {
        border-color: var(--bs-secondary);
        color: var(--bs-secondary);
      }
    }

    &.btn-success {
      &:hover {
        border-color: var(--bs-success);
        color: var(--bs-success);
      }
    }

    &.btn-danger {
      &:hover {
        border-color: var(--bs-danger);
        color: var(--bs-danger);
      }
    }

    &.white {
      background: #fff;
      color: var(--val-dark-gray);

      &:after {
        color: var(--val-primary);
      }

      &:hover {
        background: none;
        border-color: #fff;
        color: #fff;

        &:after {
          color: #fff;
        }
      }
    }

    &.dark:hover {
      color: #fff;
    }
  }
}

@media (max-width: 991px) {
  a.btn.fancy {
    padding: 5px 7px;
    font-size: 0.75rem;
    height: auto;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 250;
  }
  a[href*='tel:'] {
    pointer-events: none;
    cursor: default;
  }
}

.iconLink {
  color: var(--val-dark-gray);
  text-decoration: none;
  font-size: 1.5rem;
  display: inline-block;
  line-height: 1rem;
  letter-spacing: 0.5px;

  &:before,
  &:after {
    font-family: var(--val-icon-font);
    color: var(--val-primary);
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 1;
    font-size: 1.2rem;
    left: 0;
    font-weight: 400;
    vertical-align: sub;
  }

  &:hover {
    color: var(--val-primary);

    &:before {
      left: -5px;
    }

    &:after {
      left: 5px;
    }
  }
}

.iconLink-xl {
  padding: 0;
  font-size: 1.75rem;

  &:before,
  &:after {
    padding: 0;
    font-size: 1.75rem;
  }
}

.alink {
  @extend .iconLink;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;

  &:after {
    content: '\e5c8'; // arrow_forward
    padding-left: 5px;
  }

  &.back {
    &:before {
      content: '\e5c4'; // arrow_back
      padding-right: 5px;
    }

    &:after {
      content: '';
      padding-left: 0;
    }
  }

  &.alink-xl {
    padding: 0;
    font-size: 1.75rem;

    &:before,
    &:after {
      padding: 0;
      font-size: 1.75rem;
    }
  }
}

.clink {
  &:before,
  &:after {
    font-family: var(--val-icon-font);
    position: relative;
    z-index: 1;
    left: 0;
    vertical-align: middle;
    font-size: 0.875rem;
  }

  &:after {
    content: '\e5e1'; // arrow_forward_ios
    padding-left: 2px;
  }

  &:hover {
    color: var(--val-primary);

    &:before {
      left: -5px;
    }

    &:after {
      left: 5px;
    }
  }

  &.back {
    &:before {
      content: '\e5e0'; // arrow_back_ios
      padding-right: 2px;
    }

    &:after {
      content: '';
      padding-left: 0;
    }
  }
}

.val-details {
  position: relative;
  padding: 15px;
  border: 1px solid var(--val-light-gray-3);
  margin: 0;
  background: #fff;

  &.dark {
    background: var(--val-white-3);
  }

  & + .val-details,
  & + .val-th {
    border-top: none;
  }
}

.val-th {
  font-family: var(--val-font-family-2);
  background: var(--val-white-3);
  border: 1px solid var(--val-light-gray-3);
  padding: 7px 15px;
  letter-spacing: 0.5px;
  font-size: 0.875rem;
  width: 100%;
  align-items: center;

  & + .val-details {
    border-top: none;
  }

  .col {
    word-break: break-word;
  }
}

.val-td {
  border-bottom: 1px solid var(--val-light-gray-2);
  margin: 0;
  padding: 15px;
  font-size: 0.8rem;

  &:hover {
    background: var(--val-blue-3);
  }
}

i.toolTip:after {
  font-style: normal;
  cursor: help;
  font-family: var(--val-icon-font);
  content: '\e887'; // help
  margin: 0 3px;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.modal-content {
  color: var(--val-dark-gray);
  font-family: var(--val-font-family);

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: var(--val-dark-gray);
  }

  a {
    color: var(--val-gray);
    text-decoration: underline;

    &:hover {
      color: var(--val-primary);
      text-decoration: none;
    }
  }
}

// TODO - Need to move somewhere else. Need to figure out where.
.modal-content .contact-wrapper {
  text-align: left !important;
}

.modal-body {
  color: var(--val-gray);
}

.modal-footer {
  justify-content: center;

  .btn {
    min-width: 20%;
    font-size: 0.875rem;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.scroll-content {
  overflow-y: auto;
}

.td-1 {
  max-width: 8.33%;
}

.td-2 {
  max-width: 16.666667%;
}

.td-3 {
  max-width: 25%;
}

.td-4 {
  max-width: 33.333333%;
}

.td-5 {
  max-width: 41.666667%;
}

.td-6 {
  max-width: 50%;
}

.td-7 {
  max-width: 58.33%;
}

.td-8 {
  max-width: 66.66%;
}

.td-9 {
  max-width: 75%;
}

.td-10 {
  max-width: 83.33333333%;
}

.td-11 {
  max-width: 91.66666667%;
}

.td-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.list-actions {
  color: var(--val-dark-gray);
  background: #fff;
  font-family: var(--val-font-family-2);
  font-size: 0.85rem;
}

.x-small {
  font-size: 0.75em;
}

.d-contents {
  display: contents !important;
}

.ml-auto {
  margin-left: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

@import 'forms';
