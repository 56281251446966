form i.input-icon {
  position: relative;
  top: 45px;
  float: right;
  color: var(--val-gray-2);
  background-color: inherit;
  margin-right: 1px;
  padding: 7px 10px;
  margin-top: -10px;
  z-index: 10;

  & + input {
    padding-right: 42px;
  }
}

.custom-search-window {
  min-width: 100%;
  max-width: calc(100vw - 50px);
  max-height: 300px;
  overflow-y: auto;
  --bs-dropdown-link-active-bg: var(--val-primary) !important;
}

.alternate-checkbox.active .e-row {
  text-align: center !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

input:disabled,
input:read-only,
textarea:disabled,
textarea:read-only,
select:disabled {
  cursor: not-allowed;
  background-color: var(--val-white-3) !important;
}

.form-group {
  margin-bottom: 1rem;

  .footnote {
    font-size: 0.75rem;
    color: var(--val-gray-2);
  }
}

.alternate-checkbox {
  .e-row {
    padding-top: 0 !important;
    margin-top: 0 !important;
    text-align: center !important;
  }
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;

  .form-control {
    position: relative;
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

.form-control::placeholder {
  text-overflow: ellipsis;
  color: var(--val-gray-2);
}

.warehouse-wrapper:after {
  display: none;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.input-specs {
  margin-top: 5px;
  font-size: 12px;
  color: lighten(#767676, 20%);
  text-align: right;
}

// form validation
.field-validation-error:not(.text-danger),
.field-validation-valid:not(.text-danger) {
  color: var(--val-dark-gray);
  font-family: var(--val-font-family-2);
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-top: 7px;
  display: flex;

  span {
    border: 1px solid var(--val-orange);
    color: var(--val-orange-2);
    padding: 5px;
    display: flex;
  }
}

.field-validation-error {
  font-size: 0.875rem;
  font-family: var(--val-font-family-2);

  &.global {
    margin-bottom: 20px;
    color: var(--val-red-2);
    background: var(--val-red-3);
    border: 1px solid var(--val-red-2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    min-height: 50px;

    &:before {
      font-family: var(--val-icon-font);
      content: '\e000'; // Error
      position: relative;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      z-index: 1;
      right: 0;
      font-weight: 400;
      font-size: 1.5rem;
      padding-right: 10px;
      color: var(--val-red-2);
    }
  }
}

.validation-summary-error,
.validation-summary-success {
  width: 100%;
  margin: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    margin-bottom: 20px;
  }

  ul li,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    list-style: none;
    font-size: 0.875rem;

    &:before {
      font-family: var(--val-icon-font);
      position: relative;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      z-index: 1;
      right: 0;
      font-weight: 400;
      font-size: 1.5rem;
      padding-right: 10px;
    }

    span {
      font-weight: 600;
      padding-left: 3px;

      + span:before {
        content: ',';
        padding-right: 3px;
      }
    }
  }
}

.validation-summary-error {
  ul li,
  span {
    color: var(--val-red-2);
    background: var(--val-red-3);
    border: 1px solid var(--val-red-2);

    &:before {
      color: var(--val-red-2);
      content: '\e000'; // Error
    }
  }
}

.validation-summary-success {
  ul li,
  span {
    color: var(--val-green);
    background: var(--val-green-3);
    border: 1px solid var(--val-green);

    &:before {
      color: var(--val-green);
      content: '\e86c'; // check-circle
    }
  }
}

form {
  .alert-success {
    margin-bottom: 30px;
    color: var(--val-green);
    background: var(--val-green-3);
    border: 1px solid var(--val-green);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 10px !important;
    text-align: center;
    border-radius: 0;

    ul {
      margin: 0;
      padding: 0;
    }

    ul li,
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 10px;
      list-style: none;
      font-size: 0.875rem;

      &:before {
        font-family: var(--val-icon-font);
        color: var(--val-primary);
        position: relative;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        z-index: 1;
        right: 0;
        font-weight: 400;
        font-size: 1.5rem;
        padding-right: 10px;
      }

      span {
        font-weight: 600;
        padding-left: 3px;

        + span:before {
          content: ',';
          padding-right: 3px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .validation-summary-error,
  .validation-summary-success,
  form .alert-success {
    ul li,
    span {
      line-height: 1.2rem;
      text-align: center;
      width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .field-validation-error.global {
    margin: 0 80px 30px;
  }

  .validation-summary-error,
  .validation-summary-success,
  form .alert-success {
    // TODO: Haven't been able to see why this is needed.
    padding: 0 80px;
  }
}

form {
  margin: 30px auto;
  padding: 0;
  background: none;

  .e-group {
    margin: 0 auto;
  }

  .e-row {
    text-align: left;
    position: relative;
    margin-bottom: 30px;

    .links {
      margin-top: 3px;
      text-align: right;
    }
  }

  .form-group.b0 .e-row {
    margin-bottom: 0;
  }

  .select {
    position: relative;

    label {
      display: inherit;

      &:after {
        top: 7px;
        background: #fff;
        right: 0;
      }
    }

    select {
      border: 1px solid var(--val-gray-2);
      background: #fff !important;
      padding: 0 10px;
    }
  }

  input[type='number'],
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  // TODO: Add .md class where height 50 is needed.
  select,
  input {
    height: 50px;
  }

  select,
  textarea,
  input {
    color: var(--val-dark-gray);
    background: var(--val-white-2);
    border-color: var(--val-white-2);
    background-repeat: no-repeat;
    font-size: 0.95rem;
    border-radius: 0;
    filter: none;
    padding: 5px 10px;

    &:focus {
      border-color: var(--val-primary) !important;
      box-shadow: none;
    }
  }

  select {
    cursor: pointer;
  }

  p.note {
    text-align: left;
    margin-bottom: 20px;
  }

  p.center {
    text-align: center;
    line-height: 30px;
  }

  p.mandatory {
    text-align: center;
    margin-bottom: 15px;
  }

  p.mandatory,
  label:not(.alert-warning):not(.custom-checkbox):not(.custom-radiobox) {
    font-size: 0.875rem;
    font-family: var(--val-font-family);
    margin-bottom: 5px;

    span {
      color: var(--val-primary);
      font-size: 0.875rem;
      font-family: var(--val-font-family-2);
    }
  }

  p.supp {
    font-family: var(--val-font-family-2);
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: var(--val-gray-2);
    margin: 5px 0 0;
    text-align: right;
    position: absolute;
    right: 0;
    text-transform: lowercase;
  }

  p.sub-title {
    letter-spacing: 0.5px;
    font-family: var(--val-font-family);
    font-size: 0.95rem;
    display: block;
    margin: 20px 0;
    color: var(--val-dark-gray);
    text-align: left;
  }

  label {
    .e-label:after {
      font-family: var(--val-icon-font);
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      z-index: 1;
      font-size: 1.2rem;
      font-weight: 400;
      color: var(--val-gray);
      background: var(--val-white-2);
      left: inherit;
      position: absolute;
      right: calc(15px - 3px);
      top: calc(40px - 2px);
      padding-left: 7px;
    }
  }

  .btn-sml {
    background: var(--val-primary) !important;
    color: #fff;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-family: var(--val-font-family);
    width: 200px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    white-space: normal;
  }

  .btn-sml {
    padding: 3px 5px;
    height: 25px;
    font-size: 0.75rem;
    margin: 0;
    line-height: 10px;

    &:hover,
    &:focus {
      background: var(--val-primary-hover) !important;
      border: none;
    }

    a {
      color: #fff;
      padding: 15px;
      width: 100%;
    }

    & + .btn-sml {
      margin-left: 10px;
    }

    &.add-job,
    &.add-cart,
    &.add-cart > a {
      width: 120px;
      min-width: auto;
      max-width: 100%;
    }
  }

  .btn-red {
    background: var(--val-primary) !important;
    color: #fff;

    &:hover,
    &:focus {
      background: var(--val-primary-hover) !important;
      border: none;
    }
  }

  .btn-dark {
    background: var(--val-gray-2) !important;
    border-color: var(--val-gray-2);
    color: #fff;

    &:hover,
    &:focus {
      background: var(--val-gray) !important;
      border-color: var(--val-gray);
      color: #fff;
    }
  }

  .btn-dark-bdr {
    background: #fff !important;
    border: 1px solid var(--val-gray);
    color: var(--val-gray);

    &:hover,
    &:focus {
      background: var(--val-gray-2) !important;
      color: #fff !important;
      border: 1px solid var(--val-gray-2);
    }
  }

  .btn-darker-bdr {
    background: #fff !important;
    border: 1px solid var(--val-dark-gray);
    color: var(--val-dark-gray);

    &:hover,
    &:focus {
      background: #fff !important;
      color: var(--val-primary) !important;
      border: 1px solid var(--val-primary);
    }
  }

  .btn-brand-bdr {
    background: #fff !important;
    border: 1px solid var(--val-primary);
    color: var(--val-primary) !important;

    &:hover,
    &:focus {
      background: var(--val-primary) !important;
      color: #fff !important;
    }
  }

  input.disabled {
    background: var(--val-light-gray) !important;
    cursor: default;
    pointer-events: none;
  }

  input.input-validation-error,
  input.cable-error {
    border-color: var(--val-orange);
    -webkit-box-shadow: inset 0 0 1px 3px var(--val-orange-3);
    -moz-box-shadow: inset 0 0 1px 3px var(--val-orange-3);
    box-shadow: inset 0 0 1px 3px var(--val-orange-3);
  }

  .add-job {
    background: #fff !important;
    border: 1px solid var(--val-dark-gray);
    color: var(--val-dark-gray);

    &:hover,
    &:focus {
      background: #fff !important;
      color: var(--val-primary) !important;
      border: 1px solid var(--val-primary);
    }
  }

  .add-cart {
    background: var(--val-primary) !important;
    color: #fff;

    &:hover,
    &:focus {
      background: var(--val-primary-hover) !important;
      border: none;
    }

    &.disabled {
      background: var(--val-light-gray) !important;
      cursor: default;
      pointer-events: none;
    }
  }

  .add-job.disabled,
  .btn-brand-bdr.disabled {
    color: var(--val-light-gray-3) !important;
    cursor: default;
    pointer-events: none;
    border-color: var(--val-light-gray-3);
  }

  .links {
    text-align: center;

    a {
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      line-height: 1.2rem;
      color: var(--val-gray);
      text-decoration: underline;

      &:hover {
        color: var(--val-primary);
        text-decoration: none;
      }
    }
  }

  .e-row {
    &.checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }

    input ~ .checkbox {
      margin-top: 10px;
    }

    &.deactive {
      select,
      textarea,
      input {
        background: var(--val-light-gray-2) !important;
        border-color: var(--val-light-gray-3);
        color: var(--val-gray-2);
      }

      label.e-label:after {
        background: var(--val-light-gray-2);
        color: var(--val-gray-2);
      }
    }
  }

  .e-group.row .e-row {
    width: calc(50% - 20px);

    &:nth-child(odd) {
      margin-right: 20px;
    }

    &:nth-child(even) {
      margin-left: 20px;
    }
  }

  .duo {
    display: flex;
    justify-content: center;
    align-items: center;

    input,
    a {
      margin: 0;
    }

    input + a,
    input + input {
      margin-left: 20px;
    }
  }

  .uom-dpdn {
    position: relative;

    label {
      display: inherit;

      &:after {
        background: #fff;
        top: 1px;
        font-size: 1rem;
        right: 5px;
        height: calc(30px - 2px);
        line-height: calc(30px - 2px);
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
    }

    select {
      border: 1px solid var(--val-gray-2);
      background: #fff !important;
      font-family: var(--val-font-family-2);
      font-size: 0.8rem;
      padding: 0 5px;
      height: 30px;
      border-left: none;
      width: 75px;
    }
  }
}

@media (max-width: 991px) {
  form {
    margin: 10px auto;

    label.e-label:after {
      right: calc(15px - 5px);
      top: calc(40px - 7px);
    }

    .e-row {
      margin-bottom: 20px;
    }

    p.supp + .field-validation-error {
      margin-top: 20px;
      display: block;
    }
  }

  .uom-dpdn label.e-label:after {
    right: 5px;
    top: 1px;
  }
}

form.search {
  margin: 0 auto;
  padding: 0;
  max-width: 360px;
  position: relative;

  input,
  button {
    border-radius: 0;
    float: left;

    &:focus {
      box-shadow: none;
      border-color: var(--val-gray) !important;
    }
  }

  input {
    color: var(--val-dark-gray);
    background: #fff !important;
    border-color: var(--val-light-gray-3);
    font-size: 0.95rem;
    border: 1px solid var(--val-gray-2);
    width: 100%;
    padding-left: 45px;
  }

  button {
    width: 50px;
    border-left: none;
    border-color: var(--val-light-gray-3);
    padding: 5px;

    i {
      color: var(--val-gray);
      font-size: 1.5rem;
      top: 2px;
      position: relative;
    }

    &:hover {
      border-color: var(--val-gray);
    }
  }

  i {
    font-size: 1.5rem;
    position: absolute;
    left: 15px;
    top: calc(10px + 2px);
    color: var(--val-gray-2);
  }

  input:focus + i {
    color: var(--val-primary);
  }
}

@media (max-width: 991px) {
  form.search {
    button {
      width: 40px;
    }

    i {
      top: 7px;
    }
  }
}

@media (max-width: 767px) {
  form.search input {
    width: calc(100% - 40px);
  }
}

@media (min-width: 768px) {
  .radiobox {
    padding-top: calc(30px + 2px);
  }
  .admin-page {
    .alternate-checkbox {
      .radiobox {
        padding-top: inherit;
        margin-top: auto;
      }
    }
  }
}

.custom-radiobox {
  cursor: pointer;
  margin: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;

  &:not(:last-of-type) {
    padding-right: 15px;
  }

  .custom-input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ p {
      color: var(--val-dark-gray);
    }

    &:checked ~ .facet-value {
      color: var(--val-dark-gray);
      text-decoration: underline;
    }

    &:checked ~ select {
      color: var(--val-dark-gray);
    }
  }

  p {
    margin: 0;
    padding-left: 0;
    line-height: 0.875rem;

    span {
      display: block;
      font-family: var(--val-font-family-2);
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: var(--val-gray);
    }
  }

  .facet-value {
    font-size: 0.875rem;
  }

  .custom-input:checked ~ .custom-radio:before,
  .custom-radio:before {
    font-family: var(--val-icon-font);
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 1;
    font-size: 1.2rem;
    padding-right: 5px;
    right: 0;
    font-weight: 400;
  }

  .custom-radio:before {
    content: '\e836'; // radio_button_unchecked
    color: var(--val-light-gray-3);
  }

  .custom-input:checked ~ .custom-radio {
    &:before {
      color: var(--val-primary);
      content: '\e837'; // radio_button_checked
    }

    &.check:before {
      content: '\e2e6'; // task_alt
    }
  }
}

.checkbox-wrapper {
  justify-content: center;

  .form-label {
    margin-left: 25px;
    margin-bottom: 0 !important;
    cursor: pointer;
  }
}

.custom-checkbox {
  cursor: pointer;
  margin: 0;
  display: flex;
  position: relative;

  .custom-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 4px;
  }

  p {
    margin: 0;
    padding-left: 1.5rem;
    font-size: 0.9rem;
    font-family: var(--val-font-family-2);

    span {
      display: block;
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: var(--val-gray);
    }
  }

  .custom-input:checked ~ p {
    color: var(--val-dark-gray);
  }

  .facet-value {
    font-size: 0.875rem;
  }

  .custom-input:checked ~ .facet-value {
    color: var(--val-dark-gray);
    text-decoration: underline;
  }

  .custom-input:checked ~ select {
    color: var(--val-dark-gray);
  }

  .custom-check {
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: auto;
    border-radius: 3px;
    top: 3px;
    background-color: #fff;
    -webkit-box-shadow: inset 1px 1px 2px 0 var(--val-light-gray-2);
    -moz-box-shadow: inset 1px 1px 2px 0 var(--val-light-gray-2);
    box-shadow: inset 1px 1px 2px 0 var(--val-light-gray-2);
    border: 1px solid var(--val-light-gray-3);
  }

  .custom-input:checked ~ .custom-check {
    -webkit-box-shadow: inset 0 0 0 0 var(--val-light-gray-2);
    -moz-box-shadow: inset 0 0 0 0 var(--val-light-gray-2);
    box-shadow: inset 0 0 0 0 var(--val-light-gray-2);
    border: none;
  }

  .custom-input:checked ~ .custom-check:before {
    font-family: var(--val-icon-font);
    color: var(--val-primary);
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 1;
    padding-right: 5px;
    right: 0;
    font-weight: 400;
    content: '\e834'; // check_box
    font-size: 20px;
    left: -2px;
    top: -7px;
  }

  p span {
    line-height: 1.2;
  }
}

.reqfld {
  color: var(--val-primary);

  &:after {
    content: '*';
  }
}

.input-hint {
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: -0.1px;
  color: var(--val-gray-2);
  margin: 5px 0 0;
  text-align: right;
  position: absolute;
  right: 0;
  text-transform: lowercase;
}

.form-group {
  position: relative;
  margin-bottom: 30px;

  input[type='text'],
  input[type='password'],
  input[type='email'] {
    padding-right: 30px;
  }

  #isBlockedWrapper {
    p,
    .custom-check,
    input:checked ~ .custom-check::before {
      color: var(--val-red-2) !important;
    }

    .custom-check {
      border-color: var(--val-red-2) !important;
    }
  }

  #isBlockedLabel {
    color: var(--val-red-2) !important;
  }

  .search::placeholder {
    font-size: 0.85rem !important;
    font-style: italic !important;
  }

  .form-label {
    // icons for input fields
    &:after {
      font-family: var(--val-icon-font);
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      z-index: 1;
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--val-gray-2);
      background: inherit;
      position: absolute;
      right: 7px;
      top: 2.125rem;
    }

    &.user:after,
    &.username:after,
    &.customerName:after,
    &.externalUserFirstName:after,
    &.externalUserId:after,
    &.externalUserIdentifier:after,
    &.externalUserLastName:after,
    &.firstName:after,
    &.fullName:after,
    &.lastName:after,
    &.punchoutUsername:after,
    &.defaultUserusername:after {
      content: '\e7fd'; //person
    }

    &.password:after,
    &.newPassword:after,
    &.currentPassword:after,
    &.confirmPassword:after {
      content: '\e897'; // lock
    }

    &.customerPoNumber:after {
      content: '\e14f'; // content_paste
    }

    &.email:after,
    &.externalUserEmail:after,
    &.repEmail:after {
      content: '\e0be'; // email
    }

    &.emailCc:after {
      content: '\f187'; // forward_to_inbox
    }

    &.search:after {
      content: '\e8b6'; // search
    }

    &.box1:after {
      content: '\e1a1'; // inventory_2
    }

    &.phone:after,
    &.phoneNumber:after,
    &.contactPhoneNumber:after {
      content: '\e0cd'; // phone
    }

    &.mobile:after,
    &.contactMobileNumber:after {
      content: '\e32c'; // smartphone
    }

    &.address:after,
    &.address1:after,
    &.billingAddress1:after,
    &.billAddress1:after {
      content: '\e153'; // flag
    }

    &.addressName:after,
    &.billAddressName:after,
    &.home:after {
      content: '\e88a'; // home
    }

    &.postal:after,
    &.postalCode:after,
    &.city:after,
    &.province:after,
    &.billingPostalCode:after,
    &.billingCity:after,
    &.billingProvince:after,
    &.billPostalCode:after,
    &.billCity:after,
    &.billProvince:after {
      content: '\e55f'; // place
    }

    &.dnArw:after {
      content: '\e5cf' !important; // expand_more
      pointer-events: none;
      font-size: 1.5rem;
    }

    &.doc1:after {
      content: '\e14f'; // content_paste
    }

    &.hash:after,
    &.customer:after,
    &.customerCompanyNumber:after,
    &.customerNumber:after,
    &.address2:after,
    &.billinglAddress2:after,
    &.billAddress2:after {
      content: '\e9ef'; // tag
    }

    &.dollar:after {
      content: '\f041'; // paid
    }

    &.msg1:after {
      content: '\e0cb'; // chat_bubble_outline
    }

    &.orderNotes:after,
    &.message:after {
      content: '\e26c'; // notes
    }

    &.percent:after {
      content: '\eb58'; // percent
    }

    &.shipTo:after {
      content: '\e558'; // local_shipping
    }

    &.instructions:after {
      content: '\e6b1'; // checklist
    }

    &.listName:after {
      content: '\e896'; // list
    }

    &.maximumAmountByMonth:after,
    &.maximumAmountByOrder:after,
    &.maximumOrderValue:after,
    &.maximumMonthlyLimit:after {
      content: '\e227'; // attach_money
    }

    &.customerCompanyName:after,
    &.companyName:after,
    &.siteName:after {
      content: '\e84f'; // account_balance
    }
  }
}

/* clears the ‘X’ from search input */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

::ng-deep .quote-details .custom-checkbox {
  // No checkboxes on quote details page
  display: none;
}
